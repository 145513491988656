import axios from 'axios'
import colors from 'Components/colors.scss';
import {FieldValidationMessages} from 'Components/form'
import {FlexColumn, FlexRowCenterHorizontal} from 'Components/layout'
import {Modal} from 'Components/readonly'
import PdfDisplayError from 'Components/readonly/pdfDisplayError'
import {AuthContext} from 'Context'
import {format} from 'date-fns'
import useGetData from 'Hooks/useGetData'
import useModalController from 'Hooks/useModalController'
import React, {useContext, useEffect, useState} from 'react'
import { FormContext } from 'Components/form/formContext'
import {FormattedMessage, injectIntl} from 'react-intl'
import styled from 'styled-components'
import {Button, Dropdown, SelectButton, TextInput} from '../input'
import {ToolPanel} from '../readonly/panel'
import EditSdsDateModalContent from './modalContentComponents/editSdsDateModalContent'
import SdsVersionListModalContent from './modalContentComponents/sdsVersionListModalContent'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  object {
    width: 100%;
    flex: 1 1 auto;
  }

  ${ToolPanel} {
    padding: 4px 10px 0;

    ${Dropdown} {
      @media screen and (min-width: 640px) {
        width: 200px;
      }
    }
  }

  .currentVersion {
    color: ${colors.primary};
  }

  .notCurrentVersion {
    color: ${colors.accent};
  }
`

const SdsDateWrapper = styled(FlexColumn)`
  flex: 0 0 auto;

  .missing {
    color: ${colors.accent};
  }
`

const ScrollableDiv = styled.div`
  overflow: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 !important;

  @media screen and (min-width: 640px) {
    > :first-child {
      margin-right: 1rem;
    }
  }

  .currentVersion,
  .notCurrentVersion {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 639px) {
    flex-direction: column;
    width: 100%;
    > :not(.link-button) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

const NameItem = styled.div`
  width: 200px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  z-index: 1;

  strong {
    text-decoration: underline;
  }

  button {
    background: none;
  }

  input {
    border-color: #313038 !important;
    background-color: #313038 !important;
    color: #FFF !important;
    padding: 8px 30px 8px 15px !important;
  }
`

const isIE = /*@cc_on!@*/ false || !!document.documentMode
const LanguageComponent = isIE ? SelectButton : Dropdown

const ChemicalSdsView = injectIntl(
  ({
     intl,
     productId,
     productStatus,
     setSelectedSds,
     onUploadNewSds,
     onReplaceNewSds,
     isUploadSdsModalOpen,
     isReplaceSdsModalOpen,
     overrideDefaultLanguage,
     showValidationMessages,
     setIsChemicalSdsValid,
     editView,
     setUpdatedSimilarProducts
   }) => {
    const {userLanguage3, userLanguage} = useContext(AuthContext)
    const { setFieldValue } = useContext(FormContext)
    const [sdsListLoading, sdsList, setSdsList] = useGetData(productId ? '/rapi/chemicals/' + productId + '/sds' : null, []);
    const [sdsProductLoading, sdsProduct] = useGetData(productId ? '/rapi/chemicals/' + productId + '/names' : null, [])
    const [selectedSds, setSelectedSdsInternal] = useState(null)
    const [shouldShowIframe, setShouldShowIframe] = useState(window.innerWidth > 640)
    const [editSdsDateModalController] = useModalController({title: intl.formatMessage({id: 'chemicalsds.edit_date'})})
    const [sdsVersionListModalController] = useModalController({title: intl.formatMessage({id: 'chemicalsds.all_versions'})})
    const [diff] = useState(null)
    const [nameFieldDisabled, setNameFieldDisabled] = useState({})
    const [textFieldState, setTextFieldState] = useState({})

    const validationMessages = React.useMemo(() => {
      const errors = []
      sdsList.forEach((sds) => {
        const language = intl.formatMessage({id: 'lang.' + sds.language})
        if (!sds.reason) {
          errors.push({id: 'chemicalsds.missing_reason', values: {language}})
        }
        if (!sds.sds_date) {
          errors.push({id: 'chemicalsds.missing_date', values: {language}})
        }
      })
      return errors
    }, [intl, sdsList])

    if (setIsChemicalSdsValid) {
      setIsChemicalSdsValid(validationMessages.length === 0)
    }

    const sdsReasonLabels = {
      ISSUED: intl.formatMessage({id: 'chemicalsds.ISSUED'}),
      REVISED: intl.formatMessage({id: 'chemicalsds.REVISED'}),
      CHANGED: intl.formatMessage({id: 'chemicalsds.CHANGED'})
    }

    const handleSelectedSds = React.useCallback(
      (value) => {
        if (setSelectedSds) {
          setSelectedSds(value)
        }
        setSelectedSdsInternal(value)
      },
      [setSelectedSds]
    )

    const onResize = () => {
      setShouldShowIframe(window.innerWidth > 640)
    }

    useEffect(() => {
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    })

    useEffect(() => {
      if(!sdsProductLoading) setTextFieldState({
        name: sdsProduct.name,
        supplier: sdsProduct.supplier
      })
    }, [sdsProduct, sdsProductLoading]);

    const languages = React.useMemo(
      function () {
        const selectDefaultLanguage = () => {
          let sdsDefaultLanguage = null
          if (sdsList && sdsList.length > 0) {
            if (overrideDefaultLanguage) {
              sdsDefaultLanguage = sdsList.find((sds) => sds.language === overrideDefaultLanguage)
            }
            if (!sdsDefaultLanguage) {
              sdsDefaultLanguage = sdsList.find((sds) => sds.language === userLanguage3)
            }
            if (!sdsDefaultLanguage) {
              sdsDefaultLanguage = sdsList[0]
            }
          }
          handleSelectedSds(sdsDefaultLanguage)
        }

        selectDefaultLanguage()

        return sdsList.map(function (sds) {
          return {value: sds, label: intl.formatMessage({id: 'lang.' + sds.language})}
        })
      },
      [sdsList, handleSelectedSds, overrideDefaultLanguage, userLanguage3, intl]
    )

    const updateSdsList = (newSdsList) => {
      setSdsList(newSdsList)
      if (selectedSds) {
        handleSelectedSds(newSdsList.find((s) => s.language === selectedSds.language))
      }
    }

    const updateSds = (newSds) => {
      const newSdsList = [...sdsList]
      const updateIndex = newSdsList.findIndex((s) => s.language === selectedSds.language)
      if (updateIndex !== -1) {
        newSdsList[updateIndex] = newSds
        updateSdsList(newSdsList)
      }
    }

    const download = (id) => {
      window.open(`/rapi/chemicals/sds/${id}/download`)
    }

    const openEditSdsDateModal = () => {
      editSdsDateModalController.open({data: selectedSds})
    }

    const openSdsVersionListModal = () => {
      sdsVersionListModalController.open({data: {productId}})
    }

    const ieIframeFix =
      isIE && (isUploadSdsModalOpen || isReplaceSdsModalOpen || editSdsDateModalController.isOpen || sdsVersionListModalController.isOpen)
        ? {display: 'none'}
        : {}

    const pdfUrl =
      selectedSds &&
      (selectedSds.status === 'ACTIVE'
        ? '/rapi/chemicals/' + productId + '/sds/' + selectedSds.language
        : '/rapi/chemicals/sds/' + selectedSds.id)

    /**
     * Will toggle a text field input state between updatable and disabled
     * @param event
     * @param name
     */
    const toggleFieldState = (event, name) => {
      setNameFieldDisabled({
        [name]: !(nameFieldDisabled?.[name] ?? true)
      });
    };

    /**
     * Makes a request to check similar SDS products on text field change
     * @param value
     * @param type
     * @returns {Promise<void>}
     */
    const updateTextFieldSimilar = async (value, type) => {
      // Will make sure that respective SDS product object value is right in next step
      setFieldValue(userLanguage + '.' + type, value);

      const requestData = {
        ...textFieldState,
        [type]: value
      };
      setTextFieldState(requestData);
      if (requestData) {
        try {
          const response = await axios.post(`/rapi/chemicals/${productId}/similar`, requestData)
          setUpdatedSimilarProducts(response.data);
        } catch (error) {
          console.log(error)
        }
      }
    };

    return (
      <>
        <Modal
          modalController={editSdsDateModalController}
          ContentComponent={EditSdsDateModalContent}
          updateSds={(sds) => updateSds(sds)}
        />
        <Modal
          modalController={sdsVersionListModalController}
          ContentComponent={SdsVersionListModalContent}
          selectSds={(sds) => updateSds(sds)}
        />
        <Wrapper>
          {!sdsListLoading && (
            <ToolPanel spaceBetween>
              <ButtonContainer>
                {selectedSds && selectedSds.status === 'ACTIVE' && (
                  <span className="currentVersion">
                    <FormattedMessage id="chemicalsds.current_version"/>
                  </span>
                )}
                {selectedSds && selectedSds.status !== 'ACTIVE' && (
                  <span className="notCurrentVersion">
                    <FormattedMessage id="chemicalsds.not_current_version"/>
                  </span>
                )}
                <Button
                  invert
                  icon="pi pi-list"
                  label={intl.formatMessage({id: 'chemicalsds.all_versions'})}
                  onClick={openSdsVersionListModal}
                />
              </ButtonContainer>

              {(editView !== undefined && editView === false) && !onReplaceNewSds && (<ButtonContainer>


                <NameItem>
                  <TextInput
                    type="text"
                    name="name"
                    disabled={nameFieldDisabled?.['name'] ?? true}
                    onChange={(e) => updateTextFieldSimilar(e.target.value, 'name')} value={textFieldState.name ?? ""}
                  />
                  <Button
                    primary={(nameFieldDisabled?.['name'] ?? true)}
                    icon="pi pi-pencil"
                    onClick={(e) => toggleFieldState(e, 'name')}
                  />
                </NameItem>

                <NameItem>
                  <TextInput
                    type="text"
                    name="supplier"
                    disabled={nameFieldDisabled?.['supplier'] ?? true}
                    onChange={(e) => updateTextFieldSimilar(e.target.value, 'supplier')} value={textFieldState.supplier ?? ""}
                  />
                  <Button
                    primary={(nameFieldDisabled?.['supplier'] ?? true)}
                    icon="pi pi-pencil"
                    onClick={(e) => toggleFieldState(e, 'supplier')}
                  />
                </NameItem>

              </ButtonContainer>)}

              <ButtonContainer>
                {onReplaceNewSds && (
                  <Button
                    invert
                    label={intl.formatMessage({id: 'sds.replace_sdb'})}
                    icon="pi pi-refresh"
                    onClick={() => onReplaceNewSds(updateSdsList)}
                  />
                )}
                {onUploadNewSds && productStatus === "ACTIVE" && (
                  <Button
                    invert
                    label={intl.formatMessage({id: 'sds.upload_sdb'})}
                    icon="pi pi-upload"
                    onClick={() => onUploadNewSds(updateSdsList)}
                  />
                )}
              </ButtonContainer>
            </ToolPanel>
          )}
          {showValidationMessages && <FieldValidationMessages messages={validationMessages}/>}
          <ToolPanel>
            {(!selectedSds || selectedSds.status === 'ACTIVE') && (
              <>
                {languages && languages.length > 1 && (
                  <>
                    {!shouldShowIframe && <span>{intl.formatMessage({id: 'common.sds'})}</span>}
                    <LanguageComponent
                      disabled={sdsListLoading}
                      options={languages}
                      value={selectedSds}
                      onChange={(e) => handleSelectedSds(e.value)}
                    />
                    {!shouldShowIframe && (
                      <Button
                        label={intl.formatMessage({id: 'sds.download_sdb'})}
                        icon="pi pi-download"
                        onClick={() => download(selectedSds.id)}
                      />
                    )}
                  </>
                )}

                {languages && languages.length === 1 && !shouldShowIframe && (
                  <>
                    <Button
                      label={intl.formatMessage({id: 'sds.download_sdb'})}
                      icon="pi pi-download"
                      onClick={() => download(selectedSds.id)}
                    />
                  </>
                )}
              </>
            )}

            {editView && selectedSds && (
              <>
                <SdsDateWrapper>
                  <div>
                    {selectedSds.reason ? (
                      sdsReasonLabels[selectedSds.reason]
                    ) : (
                      <span className="missing">
                        <FormattedMessage id="chemicalsds.reason_missing"/>
                      </span>
                    )}
                  </div>
                  <div>
                    {selectedSds.sds_date ? (
                      format(selectedSds.sds_date, 'YYYY-MM-DD')
                    ) : (
                      <span className="missing">
                        <FormattedMessage id="chemicalsds.date_missing"/>
                      </span>
                    )}
                  </div>
                </SdsDateWrapper>
                <Button
                  id="chemical-sds-edit-date-button"
                  icon="pi pi-pencil"
                  label={intl.formatMessage({id: 'chemicalsds.edit_date'})}
                  onClick={openEditSdsDateModal}
                />
                {/*selectedSds.previous_sds_id &&
                  (diff ? (
                    <Button label={intl.formatMessage({ id: 'chemicalsds.show_pdf' })} onClick={() => setDiff(null)} />
                  ) : (
                    <Button
                      label={intl.formatMessage({ id: 'chemicalsds.diff_previous' })}
                      onClick={calcDiffPrevVersion}
                      isLoading={isDiffLoading}
                    />
                  ))*/}
              </>
            )}
          </ToolPanel>

          {selectedSds &&
            shouldShowIframe &&
            (diff ? (
              diff.__html ? (
                <ScrollableDiv dangerouslySetInnerHTML={diff}/>
              ) : (
                <FlexRowCenterHorizontal>
                  <FormattedMessage id="chemicalsds.no_diff"/>
                </FlexRowCenterHorizontal>
              )
            ) : (
              <object key={selectedSds.id} style={ieIframeFix} type="application/pdf" data={pdfUrl}>
                <PdfDisplayError pdfUrl={pdfUrl}/>
              </object>
            ))}
        </Wrapper>
      </>
    );
  }
);

export default ChemicalSdsView
