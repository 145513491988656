import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import csvtojson from 'csvtojson'
import axios from 'axios'

import { LoadingSpinner, ToolPanel } from 'Components/readonly'
import ImportRegisterSds from './importRegisterSds'
import { GrowlContext } from 'Context'
import { FileUpload } from 'Components/input'
import { Section } from 'Components/layout'

export default () => {
  const intl = useIntl()
  const { displayError } = useContext(GrowlContext)
  const [data, setData] = useState(null)
  const [isWorking, setIsWorking] = useState(false)
  const [errors, setErrors] = useState([])

  const keyLabels = {
    name: intl.formatMessage({ id: 'chemicals.product' }),
    supplier: intl.formatMessage({ id: 'common.supplier' }),
    location: intl.formatMessage({ id: 'common.location' }),
    bulk: intl.formatMessage({ id: 'chemicals.bulk' }),
    amount: intl.formatMessage({ id: 'chemicals.unopened' }),
    amount_opened: intl.formatMessage({ id: 'chemicals.opened' }),
    packaging_size: intl.formatMessage({ id: 'chemicals.size' }),
    unit: intl.formatMessage({ id: 'chemicals.unit' }),
    article_number: intl.formatMessage({ id: 'chemicals.article_id' }),
    organisation_article_number: intl.formatMessage({ id: 'chemicals.organisation_article_number' }),
    organisation_synonym: intl.formatMessage({ id: 'chemicals.organisation_synonym' }),
    tags: intl.formatMessage({ id: 'chemicals.tags' })
  }

  const getExistingProducts = async () => {
    try {
      const result = await axios('/rapi/chemicals/organisation/import/listproducts')
      return result.data
    } catch (error) {
      displayError(intl.formatMessage('chemicals.import.error_loading_products'))
    }
  }

  const getUnits = async () => {
    try {
      const result = await axios('/rapi/units')
      return result.data
    } catch (error) {
      displayError(intl.formatMessage('chemicals.import.error_loading_units'))
    }
  }

  const parse = async (data) => {
    const existingProducts = await getExistingProducts()
    if (!existingProducts) {
      setIsWorking(false)
      return
    }

    const units = await getUnits()
    if (!units) {
      setIsWorking(false)
      return
    }

    const parseTags = (string) =>
      string
        .trim()
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '')

    const locations = [...new Set(data.map((item) => item[keyLabels.location].trim()))]
    const tags = [...new Set(data.flatMap((item) => parseTags(item[keyLabels.tags])))]

    const products = {}
    const errors = []
    data.forEach((item, row) => {
      let product
      const productName = item[keyLabels.name]
      const productSupplier = item[keyLabels.supplier]

      if (productName in products) {
        product = products[productName+productSupplier]
      } else {
        products[productName+productSupplier] = product = {
          name: productName,
          supplier: item[keyLabels.supplier],
          locations: {},
          organisation_article_number: item[keyLabels.organisation_article_number],
          organisation_synonym: item[keyLabels.organisation_synonym],
          tags: parseTags(item[keyLabels.tags])
        }
      }

      let location
      const locationName = item[keyLabels.location]
      if (locationName in product.locations) {
        location = product.locations[locationName]
      } else {
        product.locations[locationName] = location = {
          name: locationName,
          inventory: []
        }
      }

      const foundUnit = units.find((unit) => item[keyLabels.unit] === unit.label)

      if (!foundUnit) {
        errors.push(intl.formatMessage({ id: 'chemicals.import.error_missing_unit' }, {
          unit: item[keyLabels.unit],
          row: row + 1
        }))
      }

      location.inventory.push({
        bulk: item[keyLabels.bulk],
        amount: item[keyLabels.amount],
        amount_opened: item[keyLabels.amount_opened],
        packaging_size: item[keyLabels.packaging_size],
        unit: item[keyLabels.unit],
        unit_id: foundUnit && foundUnit.value
      })
    })
    setErrors(errors)
    if (errors.length > 0) {
      setIsWorking(false)
      return
    }

    const mappedProducts = Object.values(products).map((product, index) => {
      const existingProduct = existingProducts.find((item) =>
        item.user_language_variant.some((language_variant) => {
          return ""+language_variant.name+language_variant.supplier === ""+product.name+product.supplier;
        })
      )
      return {
        ...product,
        locations: Object.values(product.locations),
        productIndex: index,
        id: existingProduct && existingProduct.id
      }
    })

    setData({
      importSds: mappedProducts.map(() => []), // generate array with an empty array for each product
      locations,
      products: mappedProducts,
      tags,
      table: data.map((item, index) => ({
        importId: index,
        name: item[keyLabels.name],
        supplier: item[keyLabels.supplier],
        location: item[keyLabels.location],
        bulk: item[keyLabels.bulk],
        amount: item[keyLabels.amount],
        amount_opened: item[keyLabels.amount_opened],
        packaging_size: item[keyLabels.packaging_size],
        unit: item[keyLabels.unit],
        article_number: item[keyLabels.article_number],
        organisation_article_number: item[keyLabels.organisation_article_number],
        organisation_synonym: item[keyLabels.organisation_synonym],
        tags: item[keyLabels.tags]
      }))
    })

    setIsWorking(false)
  };

  const handleImportFile = (e) => {
    if (e.target.value.length > 0) {
      setIsWorking(true)
      var reader = new FileReader()
      reader.onload = function (event) {
        csvtojson({
          delimiter: 'auto'
        })
          .fromString(event.target.result)
          .then(parse)
      }
      reader.readAsText(e.target.value[0].file)
    }
  }

  const setSdsForImportProduct = (index, sds) => {
    const newImportSds = [...data.importSds]
    newImportSds[index] = sds
    const newData = { ...data, importSds: newImportSds }
    setData(newData)
  }

  return (
    <>
      <LoadingSpinner isLoading={isWorking}>
        <div id="import-tab-view">
          {!data && (
            <>
              <h2>
                <FormattedMessage id="chemicals.import.title"/>
              </h2>
              <ToolPanel>
                <FileUpload
                  id="import_csv_file"
                  type="button"
                  text={intl.formatMessage({id: 'chemicals.import.select_file'})}
                  value={[]}
                  onChange={handleImportFile}
                  accept=".csv"
                />
              </ToolPanel>

              {errors && errors.length > 0 && (
                <div>
                  <h3>
                    <FormattedMessage id="chemicals.import.errors"/>
                  </h3>
                  <Section>
                    {errors.map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </Section>
                  <Section>
                    <FormattedMessage id="chemicals.import.fix_errors"/>
                  </Section>
                </div>
              )}
            </>
          )}
          {data && <ImportRegisterSds data={data} closePreview={() => setData(null)}
                                    setSdsForImportProduct={setSdsForImportProduct}/>}
        </div>
      </LoadingSpinner>
    </>
  );
}
